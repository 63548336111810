<template>
  <div>
    <a-card title="分拣计划">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" allowClear @change="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="运单号, 希音包裹号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>

            <a-popconfirm
              :title="`确定批量删除 ${pagination.total} 条数据吗? `"
              :diabled="batchDeleteLoading"
              @confirm="batchDelete"
            >
              <a-button type="danger" icon="delete" :loading="batchDeleteLoading">批量删除</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ x: true }"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @destroy="destroy" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="exportLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导出中, 请等待...</div>
    </a-modal>
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { packageList, packageBatchDelete, packageExport, packageTemplate, packageImport } from "@/apis/sorting";
import { removeItem } from "@/utils/functions";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "运单号",
          dataIndex: "tracking_number",
          width: 180,
        },
        {
          title: "运单格式",
          dataIndex: "form_id",
          width: 120,
        },
        {
          title: "目的地区域",
          dataIndex: "ursa_prefix_code",
          width: 160,
        },
        {
          title: "目的地国家",
          dataIndex: "country_code",
          width: 160,
        },
        {
          title: "目的地邮编",
          dataIndex: "postal_code",
          width: 120,
        },
        {
          title: "目的地站点",
          dataIndex: "destination_location_id",
          width: 180,
        },
        {
          title: "服务类别",
          dataIndex: "service_code",
          width: 120,
        },
        {
          title: "包装方式",
          dataIndex: "packaging_code",
          width: 160,
        },
        {
          title: "AirportId",
          dataIndex: "airport_id",
          width: 120,
        },
        {
          title: "申报总价",
          dataIndex: "declaration_value",
          width: 160,
        },
        {
          title: "希音包裹号",
          dataIndex: "ref_num",
          width: 180,
        },
        {
          title: "residential",
          dataIndex: "residential",
          width: 120,
        },
        {
          title: "敏感品标识",
          dataIndex: "type_of_goods",
          width: 120,
        },
        {
          title: "申报方式",
          dataIndex: "department",
          width: 120,
        },
        {
          title: "希音包材码",
          dataIndex: "shein_packing_code",
          width: 120,
        },
        {
          title: "在库状态",
          dataIndex: "is_sorting",
          width: 120,
          customRender: (value) => (value ? "已出库" : "在库"),
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
      exportLoading: false,
      importLoading: false,
      batchDeleteLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.dataLoading = true;
      packageList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.exportLoading = true;
      packageExport(searchForm)
        .then((response) => {
          exportExcel(response, "分拣计划列表");
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    downloadTemplate() {
      packageTemplate().then((response) => {
        exportExcel(response, "分拣计划导入模板");
      });
    },
    importExcel(item) {
      const data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      packageImport(data)
        .then(() => {
          this.$message.success("导入成功");
          this.list();
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
    batchDelete() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.batchDeleteLoading = true;
      packageBatchDelete(searchForm)
        .then(() => {
          this.$message.success("批量删除成功");
          this.list();
        })
        .finally(() => {
          this.batchDeleteLoading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
